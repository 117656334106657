var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.recruiter
    ? _c(
        "section",
        { staticClass: "p-4 bg-gray-100" },
        [
          _c("bread-crumb-component", { attrs: { links: _vm.links } }),
          _c(
            "div",
            { staticClass: "p-4 mt-8 bg-white border border-gray-200 rounded" },
            [
              _c("tabs-component", {
                attrs: { defaultTabIndex: 0, tabs: _vm.tabs },
                on: { tabSelected: _vm.tabSelectionHandler },
              }),
              _c("legacy-table-component", {
                attrs: {
                  isQuickFilterable: true,
                  quickFilterItems: _vm.quickFilters,
                  data: _vm.tableData,
                  isScrollable: true,
                  isSelectable: true,
                  isSortable: true,
                  isFilterable: true,
                  tableConfigurations: _vm.tableConfigurations,
                  initialColumnOrder:
                    _vm.applicantCollectionTableColumnOrderConfig,
                  isColumnEditable: true,
                  localStorageKey: "recruiter-details-table",
                  emptyTableMessage: "0 applicants",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "settings-right",
                      fn: function () {
                        return [
                          _c(
                            "button-component",
                            {
                              attrs: { "data-cy": "createApplicantButton" },
                              on: { click: _vm.createApplicant },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex items-center" },
                                [
                                  _c("icon-component", {
                                    attrs: {
                                      name: "person",
                                      fill: "blue",
                                      width: 13,
                                      height: 14,
                                    },
                                  }),
                                  _c("span", { staticClass: "pl-2" }, [
                                    _vm._v("Create Applicant"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  46720919
                ),
              }),
            ],
            1
          ),
          _c("create-applicant-modal", {
            ref: "createApplicantModal",
            attrs: { manager: _vm.recruiter, size: "small" },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }