
import BreadCrumbComponent, {
  BreadCrumbLink
} from '@/components/BreadCrumbComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import CreateApplicantModal from '@/components/Modals/Applicant/CreateApplicantModalComponent.vue';
import LegacyTableComponent from '@/models/Table/Legacy/LegacyTableComponent.vue';
import TabsComponent, {
  TabItem
} from '@/models/Table/Legacy/LegacyTabsComponent.vue';
import SnackbarMixin from '@/mixins/snackbarMixin';
import { Application } from '@/models/Entities/Application/Application';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import { Staff } from '@/models/Entities/Personas/Staff';
import QuickFilterComponent from '@/components/Table/QuickFilterComponent.vue';
import { RecruiterApplicant } from '@/models/Recruiter/RecruiterApplicant';
import {
  applicantCollectionTableColumnOrderConfig,
  applicantTableConfigurations
} from '@/models/Table/Configurations/ApplicantTableConfiguration';
import {
  TableConfigurations,
  TableFilterTypes
} from '@/models/Table/Legacy/LegacyTable';
import Routes from '@/router/Routes';
import ApplicationService from '@/services/application.service';
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import { ETableTabItemTypes } from '../../enums/ETableTabItemTypes';
import QuickFilterFilter from '@/models/Table/Legacy/LegacyQuickFilterFilter';

class TableViewModel {
  id: number;
  firstName: string;
  middleName: string | null;
  lastName: string;
  email: string;
  city: string | null;
  zipCode: string | null;
  homePhone: number | null;
  socialSecurityNumber: string | null;
  workPhone: number | null;
  profileIncomplete: boolean;
  recruiterSignatureRequired: boolean;
  readyForBoardSubmission: boolean;
  applicationPartiallyComplete: boolean;

  constructor(applicant: RecruiterApplicant) {
    this.id = applicant.id;
    this.firstName = applicant.identificationInformationFirstname;
    this.middleName = applicant.identificationInformationMiddlename;
    this.lastName = applicant.identificationInformationLastname;
    this.email = applicant.contactInformationEmail;
    this.city = applicant.contactInformationCity;
    this.zipCode = applicant.contactInformationZipcode;
    this.homePhone = applicant.contactInformationHomephone;
    this.workPhone = applicant.contactInformationWorkphone;
    this.socialSecurityNumber =
      applicant.identificationInformationSocialsecuritynumber;
    this.profileIncomplete = applicant.profileIncomplete;
    this.recruiterSignatureRequired = applicant.recruiterSignatureRequired;
    this.readyForBoardSubmission = applicant.readyForBoardSubmission;
    this.applicationPartiallyComplete = applicant.applicationPartiallyComplete;
  }
}

@Component<RecruiterDetailsPage>({
  components: {
    ButtonComponent,
    CreateApplicantModal,
    TabsComponent,
    LegacyTableComponent,
    QuickFilterComponent,
    IconComponent,
    BreadCrumbComponent
  }
})
export default class RecruiterDetailsPage extends Mixins(Vue, SnackbarMixin) {
  @Prop({ required: true })
  recruiter!: Staff;

  Routes = Routes;
  tableConfigurations: TableConfigurations = applicantTableConfigurations;
  applicantCollectionTableColumnOrderConfig =
    applicantCollectionTableColumnOrderConfig;
  applicationsByApplicant = new Map();
  submittedApplicantsArray: RecruiterApplicant[] = [];
  models: TableViewModel[] = [];

  tabs = [
    new TabItem(ETableTabItemTypes.OPEN_APPLICANTS),
    new TabItem(ETableTabItemTypes.SUBMITTED_APPLICANTS),
    new TabItem(ETableTabItemTypes.ARCHIVED_APPLICANTS)
  ];

  filter: ETableTabItemTypes = ETableTabItemTypes.OPEN_APPLICANTS;

  get links(): BreadCrumbLink[] {
    return [{ label: 'Recruiter' }, { label: 'Applicants' }];
  }

  tabSelectionHandler(payload: TabItem): void {
    switch (payload.label) {
      case ETableTabItemTypes.SUBMITTED_APPLICANTS:
        this.filter = ETableTabItemTypes.SUBMITTED_APPLICANTS;
        break;
      case ETableTabItemTypes.ARCHIVED_APPLICANTS:
        this.filter = ETableTabItemTypes.ARCHIVED_APPLICANTS;
        break;
      default:
        this.filter = ETableTabItemTypes.OPEN_APPLICANTS;
    }
  }

  $refs!: {
    createApplicantModal: CreateApplicantModal;
  };

  async created(): Promise<void> {
    for (let i = 0; i < this.recruiter.applicants.length; i++) {
      const applicant = this.recruiter.applicants[i];
      const applications = await this.getAllApplications(applicant);
      this.applicationsByApplicant.set(applicant, applications);
    }
  }

  get activeApplicants(): RecruiterApplicant[] {
    return this.recruiter.applicants
      .filter((applicant) => {
        return (
          !applicant.isArchived &&
          !this.submittedApplicants.find(
            (recruiterApplicant) => recruiterApplicant.id === applicant.id
          )
        );
      })
      .map((applicant) => {
        const applications = this.applicationsByApplicant.get(applicant);
        return RecruiterApplicant.newFromApplicant(applicant, applications);
      });
  }

  isNotSameApplicant(applicant: Applicant): boolean {
    return !this.submittedApplicants.find(
      (recruiterApplicant) => recruiterApplicant.id === applicant.id
    );
  }

  get submittedApplicants(): RecruiterApplicant[] {
    return this.recruiter.applicants
      .filter((applicant: Applicant) => {
        const applications = this.applicationsByApplicant.get(applicant);
        if (!applications) {
          return false;
        }
        const latestApplication =
          ApplicationService.getLatestApplication(applications);
        return latestApplication && latestApplication.boardId !== null;
      })
      .map((applicant) => {
        const applications = this.applicationsByApplicant.get(applicant);
        return RecruiterApplicant.newFromApplicant(applicant, applications);
      });
  }

  get archivedApplicants(): RecruiterApplicant[] {
    return this.recruiter.applicants
      .filter((applicant) => {
        return applicant.deletedAt;
      })
      .map((applicant) => {
        const applications = this.applicationsByApplicant.get(applicant);
        return RecruiterApplicant.newFromApplicant(applicant, applications);
      });
  }

  // is this supposed to return the function definition or applicants?
  get applicants(): RecruiterApplicant[] {
    let applicants = this.activeApplicants;
    switch (this.filter) {
      case ETableTabItemTypes.SUBMITTED_APPLICANTS:
        applicants = this.submittedApplicants;
        break;
      case ETableTabItemTypes.ARCHIVED_APPLICANTS:
        applicants = this.archivedApplicants;
        break;
    }
    return applicants;
  }

  get tableData(): TableViewModel[] {
    return this.applicants.map((applicant) => new TableViewModel(applicant));
  }

  get numberReadyForSubmission(): number {
    return this.applicants.filter(
      (applicant: RecruiterApplicant) => applicant.readyForBoardSubmission
    ).length;
  }
  get numberThatNeedRecruiterSignature(): number {
    return this.applicants.filter(
      (applicant: RecruiterApplicant) => applicant.recruiterSignatureRequired
    ).length;
  }
  get numberOfPartiallyCompletedApplications(): number {
    return this.applicants.filter(
      (applicant: RecruiterApplicant) => applicant.applicationPartiallyComplete
    ).length;
  }
  get numberWithIncompleteProfiles(): number {
    return this.applicants.filter(
      (applicant: RecruiterApplicant) => applicant.profileIncomplete
    ).length;
  }

  get quickFilters(): QuickFilterFilter[] {
    return [
      new QuickFilterFilter({
        filter: 'Ready for Board Submission',
        count: this.numberReadyForSubmission,
        column: 'readyForBoardSubmission',
        filterType: TableFilterTypes.BOOLEAN
      }),
      new QuickFilterFilter({
        filter: "Recruiter's Signature Required",
        count: this.numberThatNeedRecruiterSignature,
        column: 'recruiterSignatureRequired',
        filterType: TableFilterTypes.BOOLEAN
      }),
      new QuickFilterFilter({
        filter: 'Partial UFT Application',
        count: this.numberOfPartiallyCompletedApplications,
        column: 'applicationPartiallyComplete',
        filterType: TableFilterTypes.BOOLEAN
      }),
      new QuickFilterFilter({
        filter: 'Incomplete Profile',
        count: this.numberWithIncompleteProfiles,
        column: 'profileIncomplete',
        filterType: TableFilterTypes.BOOLEAN
      })
    ];
  }

  async createApplicant(): Promise<void> {
    try {
      await this.$refs.createApplicantModal.open();
      this.showSnackbar({
        message: `applicant has been created`
      });
      await this.$store.dispatch('recruiterModule/fetch', this.recruiter.id);
    } catch (error: any) {
      if (error) {
        this.showSnackbar({
          icon: 'caution',
          message: error.message || 'There was an error creating the applicant'
        });
      }
    }
  }

  async getAllApplications(applicant: Applicant): Promise<Application[]> {
    const applications = await this.$store.dispatch(
      'applicantModule/findApplications',
      {
        archived: true,
        candidateIds: [applicant.id]
      }
    );
    if (applications == undefined) {
      return [];
    }
    return applications;
  }
}
